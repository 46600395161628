import { useState, useEffect } from "react";
import { CertInfos } from "../types/CertsInfo";

export const CertsComponent = () => {
  const [certs, setCerts] = useState<CertInfos | null>(null);
  const fetchUrl = "https://decoder-services.tradepeg-apps.com/service-monitor/certs";

  const load = async () => {
    const response = await fetch(fetchUrl, {
      headers: new Headers({
        Authorization: "Basic " + btoa("tradepeg:tradepeg"),
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    setCerts(data as CertInfos);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {certs === null ? (
        <p>Loading...</p>
      ) : (
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Host</th>
              <th scope="col">Region</th>
              <th scope="col">Days</th>
            </tr>
          </thead>
          <tbody>
            {certs.map((cert, index) => {
              const isExpiring = cert.days < 30;
              const className = isExpiring ? "table-danger" : "";
              return (
                <tr key={index} className={className}>
                  <td>{cert.host}</td>
                  <td>{cert.region}</td>
                  <td>{cert.days}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
